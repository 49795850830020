import React from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { Layout } from '../../../components/layout'
import { SEO } from '../../../components/seo'
import { Menu } from '../../../components/menu'

import * as styles from '../../../styles/pages/solutions.module.scss'
import mainHeader from '../../../images/solutions/bpm/escritorio.jpg'

import { Contact } from '../../../components/contact/contact'
import { Footer } from '../../../components/footer'
import { FaqSection } from '../../../components/faq'
import { MethodologySection } from '../../../components/methodology'
import { SliderCustomers } from '../../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../../components/statistics'
import { bpmList } from '../../../utils/customersList'
import BreadcrumbComponent from '../../../components/breadcrumb'

const faqItems = [
  {
    question: 'O que é preciso para iniciar o projeto de consultoria?',
    answer:
      'Primeiramente, o desejo de mudar. Todo projeto de Consultoria BPM impacta nos processos da empresa, portanto requer engajamento das pessoas para que as mudanças aconteçam. O apoio da alta direção também é fundamental para o sucesso do projeto. Em termos práticos, a equipe interna precisa ter disponibilidade para levantamento de informações e para implementar as ações de melhoria junto à consultoria.',
  },
  {
    question: 'Quanto tempo leva o projeto de consultoria?',
    answer:
      'Depende do nível de maturidade de gestão da empresa. Projetos de implementação da Gestão por Processos comumente demandam de 03 a 12 meses de execução. O cronograma depende de algumas variáveis, como o escopo do projeto, quantidade de processos envolvidos no mapeamento e melhoria, quantidade de funcionários e disponibilidade da equipe interna para trabalhar com a consultoria.',
  },
  {
    question: 'Como solicitar um orçamento para um projeto de consultoria?',
    answer:
      'Informe seus dados no formulário acima para receber o contato de um Especialista em Processos. Um especialista retornará o contato para coletar informações, através de uma reunião de diagnóstico. Mais do que um orçamento, é necessário entender qual o momento atual da empresa, quais são os principais desafios, para que então possamos oferecer a melhor solução.',
  },
  {
    question: 'Como saber se o projeto trará ROI para a minha empresa?',
    answer:
      'Projetos de consultoria são investimentos, e não despesas, portanto devem proporcionar ganhos para a organização. Em cada melhoria identificada, nossos Especialistas em Melhoria Contínua calculam os ganhos quantitativos e qualitativos esperados para cada mudança a ser implementada. Analisamos as principais variáveis dos processos para encontrar melhorias de valor, relacionadas a pessoas, riscos e oportunidades, lógica do processo, sistemas de informação e indicadores de desempenho.',
  },
]

const methodologyItems = [
  {
    count: 1,
    title: 'Planejamento',
    description: (
      <>
        Inicialmente não é preciso formar uma equipe madura e com alto
        investimento para o BPM Office. Basta formar um grupo pequeno, com
        profissionais de diferentes áreas de negócio, definindo atribuições e
        atividades para que desenvolvam dentro de suas rotinas.
      </>
    ),
  },
  {
    count: 2,
    title: 'Conscientização',
    description: (
      <>
        Após constituir o escritório de processos, é necessário conscientizar
        sobre a mudança da visão departamental para processual. A alta liderança
        deve apoiar comunicando a todos, apoiando as ações do escritório,
        fornecendo autonomia adequada e participando das tomadas de decisões.
      </>
    ),
  },
  {
    count: 3,
    title: 'Políticas e procedimentos',
    description: (
      <>
        É boa prática criar um passo a passo orientando a atuação do escritório
        de processos, esclarecendo dúvidas como: o que fazer para sugerir
        melhorias, como se comunicar com o escritório, como abrir um chamado,
        como monitorar o desempenho, dentre outras.
      </>
    ),
  },
  {
    count: 4,
    title: 'Capacitação',
    description: (
      <>
        O escritório de processos deve capacitar os colaboradores para sustentar
        a gestão BPM na empresa. Além disso, deve monitorar a transformação
        organizacional, realizar diagnósticos, propor melhorias, acompanhar
        planos de ação e apresentar os resultados obtidos.
      </>
    ),
  },
]

const featuresItems = [
  {
    title: `O escritório de processos`,
    description: (
      <>
        Também denominado BPM Office, é responsável por apoiar toda a
        organização com metodologias, técnicas, ferramentas e treinamentos em
        processos, desde o nível operacional ao estratégico, sustentando as
        iniciativas BPM, promovendo um ciclo de padronização e melhoria
        contínua.
      </>
    ),
  },
  {
    title: `Papéis do escritório`,
    description: (
      <>
        O escritório de processos deve capacitar os colaboradores, prover
        ferramentas e pessoas para mapeamento e melhoria de processos, atender à
        abertura de chamados das áreas de negócio, gerenciar o desempenho de
        processos e alinhar os processos à estratégia da organização.
      </>
    ),
  },
  {
    title: `Características do escritório`,
    description: (
      <>
        As principais características do BPM Office envolvem
        multidisciplinaridade, independência, visão estratégica para conectar os
        processos às metas organizacionais, promover integração entre as áreas
        de negócio, inovação e possuir um processo para promover a melhoria
        contínua.
      </>
    ),
  },
  {
    title: `Profissionais envolvidos`,
    description: (
      <>
        De acordo com o CBOK, o escritório de processos pode ficar posicionado
        na estrutura organizacional logo abaixo da liderança executiva e conta
        com profissionais como o analista, o designer, o arquiteto e o gerente
        de processos, a depender da estrutura da organização.
      </>
    ),
  },
]

const EscritorioProcessosPage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  return (
    <Layout location={location}>
      <SEO title="High Jump - Escritório de Processos" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>Estruturação de escritório de processos</h1>
              <p>
                Conheça a consultoria para estruturação de escritório de
                processos, também denominado BPM Office, para integrar e
                melhorar continuamente as áreas de negócio.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <BreadcrumbComponent
              replacedMainPath="Gestão por processos de negocio BPM"
              replacedLabel="Escritório de Processos"
            />
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Implementação do escritório de processos (BPM Office) com o
                  objetivo de perpetuar e aprimorar a gestão por processos de
                  uma organização.
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para implementar um escritório de processos em sua organização.
							"
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={bpmList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Especialistas certificados</h2>
                  <p>
                    Em gestão por processos (ABPMP, OCEB) e gestão de projetos
                    (PMI)
                  </p>
                </div>
                <div className={styles.div3}>
                  <h2>Confiança no resultado</h2>
                  <p>Quantificando e qualificando ganhos de projeto</p>
                </div>
                <div className={styles.div4}>
                  <h2>Modalidade híbrida</h2>
                  <p>
                    Execução presencial e/ou remota em comum acordo com o
                    cliente
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default EscritorioProcessosPage
